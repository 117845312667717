import React, { useEffect } from "react";
import PetRecycling from "../templates/products/pet-recycling";
import { Helmet } from "react-helmet";
import {
  MetaTitle,
  MetaDescription,
  MetaKeywords,
  MetaOGTitle,
  MetaOGDescription,
  MetaURL,
  MetaImage,
} from "../components/meta-tags";

export default function PetRecyclingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = MetaTitle();
  const description = MetaDescription();
  const metaKeywords = MetaKeywords();
  const metaOGTitle = MetaOGTitle();
  const metaOGDesc = MetaOGDescription();
  const metaURL = MetaURL();
  const metaImage = MetaImage();

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={metaURL} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaOGTitle} />
        <meta property="og:description" content={metaOGDesc} />
        <meta property="og:url" content={metaURL} />
        <meta property="og:image" content={metaImage} />
      </Helmet>

      <PetRecycling />
    </div>
  );
}
