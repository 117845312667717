import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Footer from "../../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function petRecycling() {
  return (
    <div>
      <NavBar />
      <div style={{ position: "relative" }}>
        <img
          className="home-image"
          alt=""
          src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pet-recycling/main-banner.jpg"
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            zIndex: 0,
          }}
        ></div>
        <div className="pet-topic-box">
          <div>
            <p className="pet-box-topic">
              PIONEERING SRI LANKA’S PLASTIC RECYCLING EFFORT
            </p>
          </div>
        </div>
      </div>

      {/* session - 1 */}
      <div style={{ marginTop: "50px", marginBottom: "30px" }}>
        <center>
          <div>
            <h1 className="topic">WHY IS RECYCLING IMPORTANT?</h1>
          </div>
        </center>
        <div>
          <center>
            <div className="page-content-3">
              <div className="two-column-para">
                <div className="column-para column1-para">
                  {/* Column 1 */}
                  <center>
                    <div className="icon-image">
                      <img
                        alt=""
                        src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pet-recycling/ICON_01-01.png"
                      />
                    </div>
                  </center>
                </div>
                <div className="column-para column2-para">
                  {/* Column 2 */}
                  <div style={{ marginTop: "50px" }}>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Conserves natural resources
                    </p>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Protects ecosystems and wildlife
                    </p>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Reduces demand for raw materials
                    </p>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Saves energy
                    </p>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Cuts carbon emissions
                    </p>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Cheaper than waste collection and disposal
                    </p>
                    <p className="inside-para">
                      <span className="my-icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </span>{" "}
                      Creates jobs
                    </p>
                    <br />
                    <a
                      href="https://friendsoftheearth.uk/sustainable-living/7-benefits-recycling"
                      aria-label="link"
                      className="s1-link-pet"
                    >
                      https://friendsoftheearth.uk/sustainable-living/7-benefits-recycling
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>

      {/* session - 2 */}
      <div className="background-scroll-container">
        <div className="scroll-background-image-5"></div>
        <div className="content-sus-5">
          <div>
            <div className="pet-box">
              <div className="pet-box-container">
                <div className="pet-box-column-1">
                  {" "}
                  {/* Content for column 1 */}
                  <div style={{ width: "30%" }}></div>
                </div>
                <div className="pet-box-column-2">
                  {" "}
                  {/* Content for column 2 */}
                  <center>
                    <img
                      alt=""
                      src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pet-recycling/ICON_01-02-qgclgmsmad7elpuuduajhamr8l5j5ca5n23alg42j4.png"
                      style={{ width: "200px", height: "auto" }}
                    />
                  </center>
                </div>
                <div className="pet-box-column-3">
                  {" "}
                  {/* Content for column 3 */}
                </div>
                <div className="pet-box-column-4">
                  {" "}
                  {/* Content for column 4 */}
                  <p className="topic">TYPES OF PLASTICS WE COLLECT</p>
                  <h2 className="pet-box-topic-2">
                    POLYETHYLENE TEREPHTHALATE (PETE)
                  </h2>
                  <p className="pet-box-para">
                    PET is one of the most widely used plastics in the world. It
                    is commonly used to make plastic drink bottles and is very
                    recyclable. The sheer number of plastic bottles produced
                    around the world makes PET a very valuable resource for
                    recycling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* session - 3 */}
      <div>
        <center>
          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <div style={{ marginLeft: "50px", marginRight: "50px" }}>
              <p className="topic">OTHER RECYCLE PLASTICS</p>
            </div>

            <div className="pet-session-3">
              <div className="three-container">
                <div className="three-column">
                  <img
                    src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pet-recycling/HDPE.jpg"
                    alt=""
                    style={{
                      marginTop: "30px",
                      width: "100%",
                      height: "auto",
                      marginRight: "10px",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="s4-a-t">HIGH-DENSITY POLYETHYLENE (HDPE)</h2>
                    <p className="w-para-1">
                      HDPE is a high-strength plastic that is often used for
                      milk jugs, laundry detergent, water pipes, and other
                      applications requiring rigid plastic.
                    </p>
                  </div>
                </div>
                <div className="three-column">
                  <img
                    src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pet-recycling/LDPE.jpg"
                    alt=""
                    style={{
                      marginTop: "30px",
                      width: "100%",
                      height: "auto",
                      marginRight: "10px",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="s4-a-t">LOW-DENSITY POLYETHYLENE (LDPE)</h2>
                    <p className="w-para-1">
                      LDPE is a flexible type of plastic that is most commonly
                      used in plastic bags. The flexibility makes it suitable
                      for a wide variety of products.
                    </p>
                  </div>
                </div>
                <div className="three-column">
                  <img
                    src="https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/pet-recycling/pp.jpg"
                    alt=""
                    style={{
                      marginTop: "30px",
                      width: "100%",
                      height: "auto",
                      marginRight: "10px",
                    }}
                  />
                  <div className="s4-a">
                    <h2 className="s4-a-t">POLYPROPYLENE (PP)</h2>
                    <p className="w-para-1">
                      PP is a heat-resistant and rugged plastic that is often
                      used in products that will suffer wear and tear, such as
                      laboratory equipment, furniture, and packaging.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      {/* session - 4 */}
      <div>
        <div className="green-para">
          <center>
            <h2 className="green-para-t">WHY JOIN US?</h2>
            <p className="green-para-p">
              <FontAwesomeIcon icon={faAngleDoubleRight} /> WE OFFER THE BEST
              PRICES FOR PLASTIC WASTE
            </p>
            <p className="green-para-p">
              <FontAwesomeIcon icon={faAngleDoubleRight} /> GET CASH ON DELIVERY
            </p>
            <p className="green-para-p">
              <FontAwesomeIcon icon={faAngleDoubleRight} /> WE HAVE THE HIGHEST
              BUYING CAPACITY
            </p>
            <p className="green-para-p">
              <FontAwesomeIcon icon={faAngleDoubleRight} /> OUR RECYCLING
              FACILITIES UTILIZE THE LATEST TECHNOLOGY
            </p>
            <p className="green-para-p">
              <FontAwesomeIcon icon={faAngleDoubleRight} /> YOUR PLASTIC WASTE
              IS RECYCLED IN AN ENVIRONMENTALLY FRIENDLY MANNER CREATING MORE
              PRODUCTS​
            </p>
          </center>
        </div>
      </div>

      <Footer />
    </div>
  );
}
